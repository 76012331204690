<template>
  <div class="oauth">
    <div
      v-for="provider in providersShow"
      :key="provider.type"
      class="oauth__container"
    >
      <template v-if="connect">
        <a
          v-if="!accounts[provider.type]"
          :href="`${oauthPrefixDomain}/oauth/${provider.type}${queryString}`"
          :class="buttonClasses(provider.type)"
        >
          {{ $t('auth.connect') }} {{ provider.label }}
        </a>

        <div
          v-else
          :class="buttonClasses(provider.type)"
        >
          {{ accounts[provider.type]['name'] || $t('auth.connected') }}
        </div>
      </template>

      <a
        v-else
        :href="`${oauthPrefixDomain}/oauth/${provider.type}${queryString}`"
        :class="buttonClasses(provider.type)"
        @click="$emit('login', provider.type)"
      >
        <span>
          {{ buttonLabel(provider.label) }}
        </span>
      </a>
    </div>

    <div
      v-show="isShort"
      class="links"
    >
      <a @click="anotherMethodsShow">
        {{ $t('labels.another_methods') }}
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    emits: [
      'login',
    ],

    props: {
      accounts: {
        type: Object,
        default() {
          return {};
        },
      },

      connect: {
        type: Boolean,
        default: false,
      },

      courseId: {
        type: String,
        default: null,
      },

      onlyName: {
        type: Boolean,
        default: false,
      },

      regionId: {
        type: String,
        default: '',
      },

      schoolDomain: {
        type: String,
        default: '',
      },

      userId: {
        type: String,
        default: '',
      },

      short: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        appDomain: window.appDomain || window.location.hostname, // первый вариант для school.zenclass.ru, второй для zenclass.ru
        isShort: this.short,
      };
    },

    computed: {
      providers() {
        return [
          { label: 'Google', type: 'google', show: true },
          { label: 'VK', type: 'vkontakte', show: !this.isShort && this.$i18n.locale === 'ru' },
          { label: 'Apple', type: 'apple', show: !this.isShort },
          // { label: 'Facebook', type: 'facebook', show: !this.isShort },
          // { label: 'Github', type: 'github' },
        ];
      },

      providersShow() {
        return this.providers.filter((provider) => provider.show);
      },

      oauthPrefixDomain() {
        return `${window.location.protocol}//api.${this.appDomain}`;
      },

      queryString() {
        return `?school=${this.schoolDomain}&region_id=${this.regionId}&course_id=${this.courseId}`;
      },
    },

    methods: {
      buttonClasses(type) {
        return `oauth__button oauth__button_${type}`;
      },

      buttonLabel(label) {
        if (this.onlyName) {
          return label;
        }

        return `${this.$t('auth.continue_through')} ${label}`;
      },

      anotherMethodsShow() {
        this.isShort = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../common/sass/theme';
  @import '../../common/sass/mixins/media-queries';
  @import '../../common/sass/mixins/typography';

  .oauth {
    display: grid;
    gap: 8px;
  }

  .oauth__button {
    @include fontStyleBody16Medium;
    color: $theme-text-750;
    background: $theme-elements-bg;
    border-radius: $common-r3;
    position: relative;
    padding: 20px 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;

    &:after {
      width: 24px;
      height: 24px;
      content: '';
    }

    @include mediaXs {
      @include fontStyleBody14Regular;
      padding: 20px 16px;
    }
  }

  $providers: (
    facebook,
    vkontakte,
    google,
    apple,
  );

  @each $provider in $providers {
    .oauth__button_#{$provider}:after {
      background: url("https://widget.zenclass.ru/images/icons/#{$provider}.png") center center no-repeat;
      background-size: contain;

      @include retina {
        background-image: url("https://widget.zenclass.ru/images/icons/#{$provider}@2x.png");
      }
    }
  }
</style>
