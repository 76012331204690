import amocrm from './amocrm.json';
import auth from './auth.json';
import chat from './chat.json';
import course from './course.json';
import errors from './errors.json';
import event from './event.json';
import file from './file.json';
import flow from './flow.json';
import labels from './labels.json';
import lesson from './lesson.json';
import notifications from './notifications.json';
import numerals from './numerals.json';
import payment from './payment.json';
import product from './product.json';
import questionnaires from './questionnaires.json';
import redirector from './redirector.json';
import school from './school.json';
import stage from './stage.json';
import subscription from './subscription.json';
import tariff from './tariff.json';
import task from './task.json';
import teacher from './teacher.json';
import theme from './theme.json';
import user from './user.json';

const messages = {
  amocrm,
  auth,
  chat,
  course,
  errors,
  event,
  file,
  flow,
  labels,
  lesson,
  notifications,
  numerals,
  payment,
  product,
  questionnaires,
  redirector,
  school,
  stage,
  subscription,
  tariff,
  task,
  teacher,
  theme,
  user,
};

export default messages;
