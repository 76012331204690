import { LOCALE_EN, LOCALE_RU } from '../../common/js/constants';

export default {
  // Доступные языки интерфейса
  AVAILABLE_LOCALES: [LOCALE_RU, LOCALE_EN],

  // чаты
  CHAT_TYPE_COURSE: 'course',
  CHAT_TYPE_GROUP: 'group',
  CHAT_TYPE_P2P: 'p2p',

  // Имена кук
  COOKIE_MARKETING_GA_CLIENT_ID: 'ga_client_id',
  COOKIE_MARKETING_PARTNER_URI: 'partner_uri',
  COOKIE_MARKETING_REFERER: 'referer',

  // курсы
  COURSE_LOADING_TYPE_META: 0,
  COURSE_LOADING_TYPE_LIST: 1,
  COURSE_PAYMENT_STATUS_NEW: 'new',
  COURSE_PAYMENT_STATUS_PENDING: 'pending',
  COURSE_PAYMENT_STATUS_DECLINED: 'declined',
  COURSE_PAYMENT_STATUS_OK: 'ok',
  COURSE_TYPE_COMMON: 'common',
  COURSE_TYPE_CALENDAR: 'calendar',
  COURSE_TYPE_PRODUCT: 'product',
  COURSE_VISIBILITY_ALL: 'all',
  COURSE_VISIBILITY_REGISTERED: 'registered',
  COURSE_VISIBILITY_LIMITED: 'limited',
  COURSE_VISIBILITY_STUDENTS: 'students',
  COURSE_VISIBILITY_NONE: 'none',

  // потоки курсов
  COURSE_FLOW_DELETE_STEP_DEFAULT: 'choose-action',
  COURSE_FLOW_DELETE_ACTION_MOVE: 'move',
  COURSE_FLOW_DELETE_ACTION_EXPEL: 'expel',

  // Язык интерфейса по-умолчанию
  DEFAULT_LOCALE: 'ru',

  // Внутренние коды ошибок
  ERROR_CODE_USER_MUST_HAS_ROLE: 100,

  // мероприятия
  EVENT_INVITE_STATUS_NEW: 'new',
  EVENT_INVITE_STATUS_ACCEPTED: 'accepted',
  EVENT_LAYOUT_MODULAR: 'modular',
  EVENT_LAYOUT_SLIDER: 'slider',
  EVENT_LAYOUT_MASONRY: 'masonry',

  // уроки
  LESSON_STATUS_DRAFT: 'draft',
  LESSON_STATUS_PUBLISHED: 'published',
  LESSON_STATUS_STUDENT_FINISHED: 'passed',
  LESSON_STATUS_STUDENT_NO_ACCESS: 'unavailable',
  LESSON_STATUS_STUDENT_NOT_FINISHED: 'current',

  // причины статусов уроков
  LESSON_STATUS_REASON_STAGE_NOT_AVAILABLE: 0,
  LESSON_STATUS_REASON_NOT_STARTED_YET: 2,
  LESSON_STATUS_REASON_REQUIREMENTS_NOT_MET: 5,

  // хранилище
  LOCALSTORAGE_ACCESS_TOKEN: 'access_token',
  LOCALSTORAGE_ROLES: 'roles',
  LOCALSTORAGE_SCHOOL_NAME: 'school_name',
  LOCALSTORAGE_SCHOOL_DOMAIN: 'school_domain',
  LOCALSTORAGE_USER_ID: 'user_id',
  LOCALSTORAGE_USER_FIRST_NAME: 'first_name',
  LOCALSTORAGE_USER_SECOND_NAME: 'second_name',
  LOCALSTORAGE_USER_LAST_NAME: 'last_name',
  LOCALSTORAGE_USER_PHONE: 'phone',
  LOCALSTORAGE_USER_BIO: 'bio',
  LOCALSTORAGE_USER_TIMEZONE: 'timezone',
  LOCALSTORAGE_USER_CITY: 'city',
  LOCALSTORAGE_USER_COUNTRY: 'country',

  // оплата, биллинг
  PAYMENT_METHOD_BANK: 'zenclass',
  PAYMENT_METHOD_CREDIT: 'zenclass_credit',
  PAYMENT_METHOD_MANUAL: 'manual',
  PAYMENT_METHOD_YM: 'ym_direct',
  PAYMENT_METHOD_CLOUD_PAYMENTS: 'cloudpayments',
  PAYMENT_METHOD_HUTKI_GROSH: 'hutkigrosh',
  PAYMENT_METHOD_PAYPAL: 'paypal',
  PAYMENT_METHOD_WAYFORPAY: 'wayforpay',
  PAYMENT_METHOD_STRIPE: 'stripe',
  PAYMENT_METHOD_XSOLLA: 'xsolla',
  PAYMENT_METHOD_YANDEX_KASSA: 'yandex_kassa',
  PAYMENT_METHOD_QIWI: 'qiwi',
  PAYMENT_METHOD_FAKE: 'fake',

  PAYMENT_VARIANT_SINGLE: 'single',
  PAYMENT_VARIANT_INSTALLMENT: 'installment',
  PAYMENT_VARIANT_SUBSCRIPTION: 'subscription',
  PAYMENT_VARIANT_BANK_CREDIT: 'bank_credit',
  PAYMENT_VARIANT_BANK_INSTALLMENT: 'bank_installment',
  PAYMENT_VARIANT_FREE: 'free',

  // дэйтпикер
  PICKER_VIEW_DAY: 'date',
  PICKER_VIEW_MONTH: 'month',
  PICKER_VIEW_YEAR: 'year',

  // вопросы в заданиях
  QUESTION_TYPE_TEST_CHECKBOX: 'test_multi',
  QUESTION_TYPE_TEST_RADIO: 'test_radio',
  QUESTION_TYPE_TEXT: 'text',

  // ступени
  STAGE_STUDENT_PROGRESS_NO_ACCESS: 'unavailable',

  // курс студента
  STUDENT_COURSE_OVERHEAD_HARD: 'hard',
  STUDENT_COURSE_OVERHEAD_SOFT: 'soft',

  // задания
  TASK_ACCEPT_MODE_TEACHER: 'teacher',
  TASK_TYPE_TASK: 'task',
  TASK_TYPE_AUTO: 'auto',
  TASK_STATUS_DRAFT: 'draft',
  TASK_STATUS_PUBLISHED: 'published',
  TASK_STATUS_STUDENT_CHECKING: 'checking',
  TASK_STATUS_STUDENT_FINISHED: 'passed',
  TASK_STATUS_STUDENT_NO_ACCESS: 'unavailable',
  TASK_STATUS_STUDENT_NOT_FINISHED: 'current',
  TASK_STATUS_STUDENT_REPEATED: 'repeated',
  TASK_STATUS_STUDENT_RETURNED: 'returned',

  // оформление
  THEME_CLASS_PRODUCT: 'theme_product',
  THEME_CLASS_SCHOOL: 'theme_school',
  THEME_CLASS_PREVIEW: 'theme_preview',
  THEME_KEY_ACCENT_COLOR: 'accent_color',
  THEME_KEY_NAME: 'theme_name',

  // реквизиты
  SCHOOL_REQUISITES_STATUS_FAILED: 'failed',
  SCHOOL_REQUISITES_STATUS_INVALID: 'invalid',
  SCHOOL_REQUISITES_STATUS_VALID: 'valid',
  SCHOOL_REQUISITES_STATUS_CHECKING: 'checking',

  // отчеты о заданиях и практиках
  REPORT_STATUS_ACCEPTED: 'accepted',
  REPORT_STATUS_NEW: 'new',
  REPORT_STATUS_REPEATED: 'repeated',
  REPORT_STATUS_RETURNED: 'returned',

  RESPONSE_STATUS_UNAUTHORIZED: 401,
  RESPONSE_STATUS_PAYMENT_REQUIRED: 402,
  RESPONSE_STATUS_STUDENT_BLOCKED: 423,

  ROUTE_CHATS: 'courses.chats',
  ROUTE_CHAT: 'courses.chat',
  ROUTE_COURSE_EDIT: 'courses.edit',
  ROUTE_COURSE_EDIT_STRUCTURE: 'courses.edit.structure',
  ROUTE_COURSE_EDIT_CONSTRUCT: 'courses.edit.construct',
  ROUTE_COURSE_EDIT_CONSTRUCT_OVERVIEW: 'courses.edit.construct.overview',
  ROUTE_COURSE_EDIT_FLOWS: 'courses.edit.flows',
  ROUTE_COURSE_EDIT_LESSONS: 'courses.edit.lessons',
  ROUTE_COURSE_EDIT_LESSON: 'courses.edit.lesson',
  ROUTE_COURSE_EDIT_SETTINGS: 'courses.edit.settings',
  ROUTE_COURSE_EDIT_SETTINGS_INFO: 'courses.edit.settings.info',
  ROUTE_COURSE_EDIT_SETTINGS_ACCESS: 'courses.edit.settings.access',
  ROUTE_COURSE_EDIT_SETTINGS_EMBED: 'courses.edit.settings.embed',
  ROUTE_COURSE_EDIT_SETTINGS_THEME: 'courses.edit.settings.theme',
  ROUTE_COURSE_EDIT_SETTINGS_INFOBLOCK: 'courses.edit.settings.infoblock',
  ROUTE_COURSE_EDIT_SETTINGS_ACTIONS: 'courses.edit.settings.actions',
  ROUTE_COURSE_EDIT_STUDENTS: 'courses.edit.students',
  ROUTE_COURSE_EDIT_STUDENTS_REQUESTS: 'courses.edit.students.requests',
  ROUTE_COURSE_EDIT_TASK: 'courses.edit.task',
  ROUTE_COURSE_EDIT_TASK_CONTENT: 'courses.edit.task.content',
  ROUTE_COURSE_EDIT_TASK_QUESTIONS: 'courses.edit.task.questions',
  ROUTE_COURSE_EDIT_TASK_POINTS: 'courses.edit.task.points',
  ROUTE_COURSE_EDIT_PROMOCODES: 'courses.edit.promocodes',
  ROUTE_COURSE_EDIT_TARIFFS: 'courses.edit.tariffs',
  ROUTE_COURSE_EDIT_TARIFFS_PREVIEW: 'courses.edit.tariffs.preview',
  ROUTE_COURSE_EDIT_TARIFFS_TARIFF: 'courses.edit.tariffs.tariff',
  ROUTE_COURSE_EDIT_TARIFFS_TARIFF_ACCESS: 'courses.edit.tariffs.tariff.access',
  ROUTE_COURSE_EDIT_TARIFFS_TARIFF_CONTENT: 'courses.edit.tariffs.tariff.content',
  ROUTE_COURSE_EDIT_TARIFFS_TARIFF_INFO: 'courses.edit.tariffs.tariff.info',
  ROUTE_COURSE_EDIT_TARIFFS_TARIFF_PAYMENT: 'courses.edit.tariffs.tariff.payment',
  ROUTE_COURSE_EDIT_STATS: 'courses.edit.statistics',
  ROUTE_COURSE_SHOW: 'courses.show',
  ROUTE_COURSE_SHOW_LESSON: 'courses.show.lesson',
  ROUTE_COURSE_SHOW_TASK: 'courses.show.task',
  ROUTE_COURSE_STUDENT_SHOW: 'courses.student.show',
  ROUTE_COURSE_STUDENT_SHOW_LESSON: 'courses.student.show.lesson',
  ROUTE_COURSE_STUDENT_SHOW_LESSON_CURRENT: 'courses.student.show.lesson.current',
  ROUTE_COURSE_STUDENT_SHOW_LESSONS: 'courses.student.show.lessons',
  ROUTE_COURSE_STUDENT_SHOW_TASK: 'courses.student.show.task',
  ROUTE_EMAIL_CONFIRM: 'auth.email.confirm',
  ROUTE_EVENT_USER_SHOW: 'event.user.show',
  ROUTE_EVENT_USER_SHOW_PUBLIC: 'event.user.show.public',
  ROUTE_EVENT_LANDING_USER_SHOW: 'event.landing.user.show',
  ROUTE_EVENT_LANDING_USER_SHOW_PUBLIC: 'event.landing.user.show.public',
  ROUTE_EVENT_SPEECH_USER_SHOW: 'event.speech.user.show',
  ROUTE_EVENT: 'event',
  ROUTE_EVENT_EDIT: 'event.edit',
  ROUTE_EVENT_EDIT_PAYMENTS: 'event.edit.payments',
  ROUTE_EVENT_EDIT_PROMOCODES: 'event.edit.promocodes',
  ROUTE_EVENT_EDIT_REFERRAL: 'event.edit.referral',
  ROUTE_EVENT_EDIT_REFERRAL_SETTINGS: 'event.edit.referral.settings',
  ROUTE_EVENT_EDIT_REFERRAL_USERS: 'event.edit.referral.users',
  ROUTE_EVENT_EDIT_SCHEDULE: 'event.edit.schedule',
  ROUTE_EVENT_EDIT_PROGRAM: 'event.edit.program',
  ROUTE_EVENT_PROGRAM_EDIT: 'event.program.edit',
  ROUTE_EVENT_PROGRAM_EDIT_DAY: 'event.program.edit.day',
  ROUTE_EVENT_PROGRAM_EDIT_DAY_CONTENT: 'event.program.edit.day.content',
  ROUTE_EVENT_PROGRAM_EDIT_DAY_SETTINGS: 'event.program.edit.day.settings',
  ROUTE_EVENT_PROGRAM_EDIT_STREAM: 'event.program.edit.stream',
  ROUTE_EVENT_PROGRAM_EDIT_SPEECH: 'event.program.edit.speech',
  ROUTE_EVENT_PROGRAM_EDIT_SPEECH_CONTENT: 'event.program.edit.speech.content',
  ROUTE_EVENT_PROGRAM_EDIT_SPEECH_VIDEO: 'event.program.edit.speech.video',
  ROUTE_EVENT_PROGRAM_EDIT_SPEECH_CONTENT_BLOCKS: 'event.program.edit.speech.content.blocks',
  ROUTE_EVENT_EDIT_PROGRAM_DAY: 'event.edit.program.day',
  ROUTE_EVENT_EDIT_PROGRAM_DAY_EDIT: 'event.edit.program.day.edit',
  ROUTE_EVENT_EDIT_PROGRAM_STREAM: 'event.edit.program.stream',
  ROUTE_EVENT_EDIT_PROGRAM_STREAM_EDIT: 'event.edit.program.stream.edit',
  ROUTE_EVENT_EDIT_PROGRAM_SPEECH_EDIT: 'event.edit.program.speech.edit',
  ROUTE_EVENT_EDIT_PROGRAM_SPEECH_EDIT_SETTINGS: 'event.edit.program.speech.edit.settings',
  ROUTE_EVENT_EDIT_PROGRAM_SPEECH_EDIT_CONTENT: 'event.edit.program.speech.edit.content',
  ROUTE_EVENT_EDIT_PROGRAM_SPEECH_EDIT_VIDEO: 'event.edit.program.speech.edit.video',
  ROUTE_EVENT_EDIT_SETTINGS: 'event.edit.settings',
  ROUTE_EVENT_EDIT_SETTINGS_CARD: 'event.edit.settings.card',
  ROUTE_EVENT_EDIT_SETTINGS_NOTIFICATIONS: 'event.edit.settings.notifications',
  ROUTE_EVENT_EDIT_SETTINGS_ACCESS: 'event.edit.settings.access',
  ROUTE_EVENT_EDIT_SETTINGS_THEME: 'event.edit.settings.theme',
  ROUTE_EVENT_EDIT_SETTINGS_REGISTRATION: 'event.edit.settings.registration',
  ROUTE_EVENT_EDIT_SETTINGS_ACTIONS: 'event.edit.settings.actions',
  ROUTE_EVENT_EDIT_SETTINGS_OTHER: 'event.edit.settings.other',
  ROUTE_EVENT_EDIT_USERS: 'event.edit.users',
  ROUTE_EVENT_EDIT_REQUESTS: 'event.edit.requests',
  ROUTE_EVENT_EDIT_LANDING: 'event.edit.landing',
  ROUTE_EVENT_EDIT_LANDING_CONSTRUCTOR: 'event.edit.landing.constructor',
  ROUTE_EVENT_EDIT_LANDING_STRUCTURE: 'event.edit.landing.structure',
  ROUTE_INDEX: 'index',
  ROUTE_LOGIN_SCHOOL: 'auth.login_school',
  ROUTE_OWNER_SCHOOL: 'owner.school',
  ROUTE_OWNER_SCHOOL_EVENTS: 'owner.school.events',
  ROUTE_OWNER_SCHOOL_EVENTS_CREATE: 'owner.school.events.create',
  ROUTE_OWNER_SCHOOL_EVENTS_EDIT: 'owner.school.events.edit',
  ROUTE_OWNER_SCHOOL_SETTINGS: 'owner.school.settings',
  ROUTE_OWNER_SCHOOL_SETTINGS_INFO: 'owner.school.settings.info',
  ROUTE_OWNER_SCHOOL_SETTINGS_COVER: 'owner.school.settings.cover',
  ROUTE_OWNER_SCHOOL_SETTINGS_ACCESS: 'owner.school.settings.access',
  ROUTE_OWNER_SCHOOL_SETTINGS_INTEGRATIONS: 'owner.school.settings.integrations',
  ROUTE_OWNER_SCHOOL_SETTINGS_API: 'owner.school.settings.api',
  ROUTE_OWNER_SCHOOL_SETTINGS_OTHER: 'owner.school.settings.other',
  ROUTE_OWNER_SCHOOL_SETTINGS_ACTIONS: 'owner.school.settings.actions',
  ROUTE_OWNER_SCHOOL_SETTINGS_THEME: 'owner.school.settings.theme',
  ROUTE_OWNER_SCHOOL_SETTINGS_DOCS: 'owner.school.settings.docs',
  ROUTE_OWNER_SCHOOL_SETTINGS_VERIFICATIONS: 'owner.school.settings.verifications',
  ROUTE_OWNER_SCHOOL_REFERRAL: 'owner.school.referral',
  ROUTE_OWNER_SCHOOL_TARIFFS_CONSTRUCT: 'owner.school.tariffs.construct',
  ROUTE_OWNER_SCHOOL_USERS: 'owner.school.users',
  ROUTE_OWNER_SCHOOL_USERS_EMPLOYEES: 'owner.school.users.employees',
  ROUTE_OWNER_SCHOOL_USERS_STUDENTS: 'owner.school.users.students',
  ROUTE_OWNER_SCHOOL_QUESTIONNAIRES: 'owner.school.questionnaires',
  ROUTE_OWNER_SCHOOL_QUESTIONNAIRES_STATS: 'owner.school.questionnaires.stats',
  ROUTE_OWNER_SCHOOL_MEDIA: 'owner.school.settings.media',
  ROUTE_OWNER_SCHOOL_MEDIA_COLLECTION: 'owner.school.settings.media.collection',
  ROUTE_OWNER_SCHOOL_MEDIA_IMPORT: 'owner.school.settings.media.import',
  ROUTE_PASSWORD_RESET_CONFIRM: 'auth.password_reset.confirm',
  ROUTE_PAYMENT_FAILURE: 'payment.failure',
  ROUTE_PAYMENT_SUCCCESS: 'payment.success',
  ROUTE_PRODUCT_CREATE: 'product.create',
  ROUTE_PRODUCT_BY_CODE: 'product.referral.by_code',
  ROUTE_PRODUCTS: 'products',
  ROUTE_PUBLIC_COURSE: 'public.course',
  ROUTE_PUBLIC_EVENT: 'public.event',
  ROUTE_PUBLIC_EVENT_PAYMENT: 'public.event.payment',
  ROUTE_PUBLIC_MEDIA: 'public.media',
  ROUTE_PUBLIC_PPAM: 'public.ppam',
  ROUTE_PUBLIC_TARIFF: 'public.tariff',
  ROUTE_PUBLIC_TARIFF_PAYMENT: 'public.tariff.payment',
  ROUTE_PUBLIC_PRODUCTS: 'public.products',
  ROUTE_PUBLIC_PRODUCT_TARIFFS: 'public.products.tariffs',
  ROUTE_REGISTER_SCHOOL: 'auth.register_school',
  ROUTE_REGISTER_SCHOOL_STEP2: 'auth.register_school.step2',
  ROUTE_REGISTER_STUDENT: 'auth.register_student',
  ROUTE_REGISTER_EVENTS_USER: 'auth.register_student.events.user',
  ROUTE_SCHOOL: 'school',
  ROUTE_SCHOOL_ADVERTISEMENT: 'school.advertisement',
  ROUTE_SCHOOL_AMOCRM: 'school.amocrm',
  ROUTE_SCHOOL_AMOCRM_INTEGRATION_PROCESSES: 'school.amocrm.integration.processes',
  ROUTE_SCHOOL_AMOCRM_INTEGRATION_PROCESS_CREATE: 'school.amocrm.integration.process.create',
  ROUTE_SCHOOL_AMOCRM_INTEGRATION_PROCESS: 'school.amocrm.integration.process',
  ROUTE_SCHOOL_AUTOMATION: 'school.automation',
  ROUTE_SCHOOL_AUTOMATION_DASHBOARD: 'school.automation.dashboard',
  ROUTE_SCHOOL_AUTOMATION_PROCESS_CREATE: 'school.automation.process.create',
  ROUTE_SCHOOL_AUTOMATION_PROCESS_EDIT: 'school.automation.process.edit',
  ROUTE_SCHOOL_AUTOMATION_GROUP_DASHBOARD: 'school.automation.group.dashboard',
  ROUTE_SCHOOL_AUTOMATION_GROUP_EDIT: 'school.automation.group.edit',
  ROUTE_SCHOOL_AUTOMATION_PROCESS_CREATE_IN_GROUP: 'school.automation.process.create_in_group',
  ROUTE_SCHOOL_AUTOMATION_PROCESS_EDIT_IN_GROUP: 'school.automation.process.edit_in_group',
  ROUTE_SCHOOL_FINANCE: 'school.finance',
  ROUTE_SCHOOL_FINANCE_BALANCE: 'school.finance.balance',
  ROUTE_SCHOOL_FINANCE_PAM_LIST: 'school.finance.pam_list',
  ROUTE_SCHOOL_FINANCE_STORAGE: 'school.finance.storage',
  ROUTE_SCHOOL_FINANCE_TARIFFS: 'school.finance.tariffs',
  ROUTE_SCHOOL_FINANCE_OVERVIEW: 'school.finance.overview',
  ROUTE_SCHOOL_FINANCE_PAYMENT_SETTINGS: 'school.finance.payment_settings',
  ROUTE_SCHOOL_FINANCE_AVAILABLE_TARIFFS: 'school.finance.tariff_list',
  ROUTE_SCHOOL_FINANCE_CHECKOUT: 'school.finance.checkout',
  ROUTE_SCHOOL_FINANCE_PENDING_PAYMENTS: 'school.finance.pending_payments',
  ROUTE_SCHOOL_FINANCE_TERMS: 'school.finance.terms',
  ROUTE_SCHOOL_STATS: 'school.stats',
  ROUTE_START: 'start',
  ROUTE_RESET: 'reset',
  ROUTE_STUDENT_INVITE: 'student.invite',
  ROUTE_TEACHER_DESKTOP: 'teacher.desktop',
  ROUTE_TEACHER_DESKTOP_DASHBOARD: 'teacher.desktop.dashboard',
  ROUTE_TEACHER_DESKTOP_REPORT: 'teacher.desktop.report',
  ROUTE_USER: 'user',
  ROUTE_USER_COURSES: 'user.courses',
  ROUTE_USER_EMPLOYEES_COMMENTS: 'user.employees.comments',
  ROUTE_USER_FEED: 'user.feed',
  ROUTE_USER_ME_EDIT: 'user.me.edit',
  ROUTE_CONTACT_DATA_FILL: 'school.email_and_phone.fill',

  // пользователи
  USER_ROLE_ADMIN: 'admin',
  USER_ROLE_EDITOR: 'editor',
  USER_ROLE_OWNER: 'owner',
  USER_ROLE_STUDENT: 'student',
  USER_ROLE_TEACHER: 'teacher',
  USER_STATUS_ACTIVE: 'active',
  USER_STATUS_DISABLED: 'disabled',

  // действия в ленте активности пользователя
  USER_ACTIVITIES_ACTION_ADD: 'add',
  USER_ACTIVITIES_ACTION_SUBMIT: 'submit',
  USER_ACTIVITIES_TYPE_TASK: 'task',

  // ошибки
  PAGE_NOT_FOUND: 'notFound',

  // фигуры
  SHAPE_SQUARE: 'square',
  SHAPE_LANDSCAPE: 'landscape',
  SHAPE_PORTRAIT: 'portrait',

  // выравнивание
  ALIGNMENT_LEFT: 'left',
  ALIGNMENT_CENTER: 'center',
  ALIGNMENT_RIGHT: 'right',

  // цели
  REGISTER_STUDENT: 'zenclass_register_student',
  REGISTER_TO_PRODUCT: 'zenclass_register_to_product',
  PRODUCT_PAYMENT: 'zenclass_product_payment',

  // гет-параметры
  GET_PARAM_SUCCESSFUL_PAYMENT: 'successful_payment',
};

export const CONTENTABLE_TYPE_LESSON = 'lesson';

export const CONTENTABLE_TYPE_LESSON_ITEM = 'lesson_item';

export const CONTENTABLE_TYPE_TASK = 'task';

export const CONTENTABLE_TYPE_TASK_ITEM = 'task_item';

export const CONTENTABLE_TYPE_EVENT_LANDING = 'event_landings';

export const CONTENTABLE_TYPE_EVENT_SPEECHES = 'event_speeches';

export const CONTENTABLE_TYPE_LANDING = 'landing';

export const CONTENTABLE_TYPE_SPEECH = 'speech';

export const GRID_ALIGN_OPTIONS = ['stretch', 'start', 'end', 'center'];

export const TYPES_SCHOOL_PAYMENTS = 'school_payments';

export const PAYMENT_ACCEPT = 'accept';
export const PAYMENT_REJECT = 'reject';

export const SCHOOL_ACTION_TYPE_REFILL = 'refill';
export const SCHOOL_ACTION_TYPE_REFUND_PLATFORM = 'refund-platform';
export const SCHOOL_ACTION_TYPE_REFUND_PRODUCT = 'refund-product';
export const SCHOOL_ACTION_TYPE_WITHDRAW = 'withdraw';
export const SCHOOL_ACTION_TYPE_SUBSCRIPTION = 'subscription';
export const SCHOOL_ACTION_TYPE_CANCEL_SUBSCRIPTION = 'cancel-subscription';
export const SCHOOL_ACTION_TYPE_CHANGE_SUBSCRIPTION = 'change-subscription';
export const SCHOOL_ACTION_TYPE_BALANCE_ADJUSTMENT = 'balance-adjustment';
export const SCHOOL_ACTION_TYPE_STORAGE = 'storage';
export const SCHOOL_ACTION_TYPE_CHANGE_STORAGE = 'change-storage';
export const SCHOOL_ACTION_TYPE_COURSE = 'course';
export const SCHOOL_ACTION_TYPE_COURSE_PRODUCT = 'course-product';
export const SCHOOL_ACTION_TYPE_COURSE_GRANT_ACCESS = 'course-grant-access';
export const SCHOOL_ACTION_TYPE_EVENT = 'event';
export const SCHOOL_ACTION_TYPE_PRODUCT = 'product';
export const SCHOOL_ACTION_TYPE_ERRONEOUS_PRODUCT_PAYMENT = 'erroneous-product-payment';
export const SCHOOL_ACTION_TYPE_TARIFF = 'tariff';

export const SCHOOL_PRODUCT_TYPE_EVENTS = 'events';
export const SCHOOL_PRODUCT_TYPE_COURSES = 'courses';

export const SCHOOL_SIGNIN_LOGIN = 'signin_login';

export const EVENT_PROGRAM_TYPE_DAY = 'day';
export const EVENT_PROGRAM_TYPE_STREAM = 'stream';
export const EVENT_PROGRAM_TYPE_SPEECH = 'speech';

export const EVENT_PROGRAM_SPEECH_TIME_REQUIRED = 'required';
export const EVENT_PROGRAM_SPEECH_TIME_MORE = 'more';

export const EVENT_PROGRAM_EXAMPLE_DAY_NAME = 'example.day.name';
export const EVENT_PROGRAM_EXAMPLE_DAY_DESCRIPTION = 'example.day.description';
export const EVENT_PROGRAM_EXAMPLE_DAY_ADD = 'example.day.add';
export const EVENT_PROGRAM_EXAMPLE_STREAM_NAME = 'example.stream.name';
export const EVENT_PROGRAM_EXAMPLE_STREAM_ADD = 'example.stream.add';
export const EVENT_PROGRAM_EXAMPLE_STREAM_SAVE = 'example.stream.save';

export const MEDIA_QUERY_DEVICE_MOUSE = '(hover: hover) and (pointer: fine)';

export const TYPE_AUDIO = 'audio';
export const TYPE_EVENT_SCHEDULE = 'event_schedule';
export const TYPE_FILE = 'file';
export const TYPE_GOOGLE_DOC = 'gdocs';
export const TYPE_HTML = 'html';
export const TYPE_IMAGE = 'image';
export const TYPE_INFO_CARD = 'info_card';
export const TYPE_PAYMENT = 'payment';
export const TYPE_PRODUCT_CARD = 'product_card';
export const TYPE_PRODUCT_REFERRAL = 'referral';
export const TYPE_PROTECTED_VIDEO = 'protected_video';
export const TYPE_QUESTIONNAIRE = 'questionnaire';
export const TYPE_TEXT = 'text';
export const TYPE_VIDEO = 'video';
export const TYPE_WEBINAR_YT = 'webinar_yt';

/**
 * Поддерживаемые видео-провайдеры
 */
export const VIMEO = 'vimeo';
export const KNSCP = 'kinescope';
export const BMSTR = 'boomstream';

export const IMAGE_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/ico',
  'image/x-icon',
  'image/webp',
];

export const IMAGE_TYPES_WITH_ANIMATION = IMAGE_TYPES.concat('image/gif');

export const ROUTE_META_IGNORE_UNSAVED_GUARD = 'ignoreUnsaved';
export const ROUTE_META_IGNORE_SCROLL_BEHAVIOR = 'ignoreScrollBehavior';
export const ROUTE_TELEGRAM_BOT = 'telegram.bot';
export const ROUTE_REDIRECTOR = 'tools.redirector';

export const PROMOCODE_TYPE_PERCENT = 'percent';
export const PROMOCODE_TYPE_FIXED = 'fixed';

/**
 * Типы виджетов-чатов
 */
export const CARROT = 'carrot';
export const FRESHDESK = 'freshdesk';
export const INTERCOM = 'intercom';

/**
 * Значения видимости документа
 */
export const DOC_VISIBLE = 'visible';

/**
 * Ключ для LocalStorage: хранение реф.кодов для покупки продуктов
 */
export const LS_REF_CODES = 'product_ref_codes';

/**
 * Статус импорта
 */
export const PROGRESS_STATUS_START = 'start';
export const PROGRESS_STATUS_PROCESS = 'process';
export const PROGRESS_STATUS_FINISHED = 'finished';
export const PROGRESS_STATUS_FAILED = 'failed';

/**
 * Тип превью обложки школы
 */
export const COVER_PREVIEW_TYPE_NONE = 0;
export const COVER_PREVIEW_TYPE_DESKTOP = 1;
export const COVER_PREVIEW_TYPE_MOBILE = 2;

/**
 * Режимы отображения результатов задания
 */
export const TASK_RESULT_DISPLAY_MODE_DETAILED = 'detailed';
export const TASK_RESULT_DISPLAY_MODE_MEDIUM = 'medium';
export const TASK_RESULT_DISPLAY_MODE_STRICT = 'strict';

/**
 * Модификаторы ограничения продаж календарного курса:
 * останавливать продажи до или после старта курса
 */
export const COURSE_SALE_LIMIT_STOP_BEFORE_START = 'before';
export const COURSE_SALE_LIMIT_STOP_AFTER_START = 'after';

/**
 * Каналы отправки уведомлений пользователям
 */
export const NOTIFICATION_CHANNEL_MAIL = 'mail';
export const NOTIFICATION_CHANNEL_TELEGRAM = 'telegram';

export const PUBLIC_YOUR_PRODUCTS_ID = 'your_products';
export const PUBLIC_ALL_PRODUCTS_ID = 'all_products';

export const ALLOWED_SUBSCRIPTION_PERIODS = [
  'month',
  'year',
];

export const SCHOOL_STATISTICS_SCOPE_PRODUCT = 'product';
export const SCHOOL_STATISTICS_SCOPE_PLATFORM = 'platform';

export const BACKEND_ERROR_STATUS_NOT_FOUND = 404;
export const BACKEND_ERROR_STATUS_FORBIDDEN = 403;

/**
 * Поддерживаемые интервалы для рекуррентных платежей.
 * Минуты и часы поддерживаются на всякий случай (может пригодиться
 * во время разработки/дебага). В проде не используются.
 */
export const RECURRENT_INTERVAL_MINUTE = 1;
export const RECURRENT_INTERVAL_HOUR = 2;
export const RECURRENT_INTERVAL_DAY = 3;
export const RECURRENT_INTERVAL_WEEK = 4;
export const RECURRENT_INTERVAL_MONTH = 5;
export const RECURRENT_INTERVAL_YEAR = 6;
