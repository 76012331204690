<template>
  <div
    ref="activator"
    class="z-tooltip__activator"
    :class="activatorClasses"
    v-bind="$attrs"
    @mouseenter="value = true"
    @mouseleave="value = false"
  >
    <slot name="activator" />
  </div>

  <teleport to="body">
    <transition>
      <div
        v-if="value"
        ref="tooltip"
        class="z-tooltip"
        :style="styles"
      >
        <slot />
      </div>
    </transition>
  </teleport>
</template>

<script>
  const PADDING_FROM_SCREEN = 16;
  const PADDING_FROM_ACTIVATOR = 8;

  export default {
    name: 'AppTooltip',

    emits: ['update:modelValue'],

    props: {
      modelValue: {
        type: Boolean,
        default: false,
      },

      disabled: {
        type: Boolean,
        default: false,
      },

      block: {
        type: Boolean,
        default: false,
      },
    },

    mounted() {
      this.innerValue = this.modelValue;
    },

    beforeUnmount() {
      this.removeListeners();
      this.activator = null;
    },

    data: () => ({
      className: '',
      innerValue: false,
      activator: null,
      top: 0,
      left: 0,
    }),

    computed: {
      value: {
        get() {
          return this.innerValue;
        },

        set(value) {
          if (this.disabled) {
            return;
          }

          this.innerValue = value;
          this.$emit('update:modelValue', value);
        },
      },

      styles() {
        return {
          top: this.top,
          left: this.left,
        };
      },

      activatorClasses() {
        return {
          block: this.block,
        };
      },
    },

    methods: {
      addListeners() {
        document.addEventListener('scroll', this.setPositions, true);
      },

      removeListeners() {
        document.removeEventListener('scroll', this.setPositions, true);
      },

      setActivator() {
        if (!this.activator) {
          this.$nextTick(() => {
            this.activator = this.$refs.activator;
            this.setPositions();
          });
        }
      },

      showTooltip() {
        this.setActivator();
        setTimeout(() => {
          this.addListeners();
        }, 10);
      },

      hideTooltip() {
        this.removeListeners();
        this.activator = null;
      },

      setPositions() {
        const content = this.$refs.tooltip;
        if (!content) {
          return;
        }

        const windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        const windowWidth = document.documentElement.clientWidth || document.body.clientWidth;
        const contentRect = content.getBoundingClientRect();

        const activatorRect = this.activator.getBoundingClientRect();

        const top = activatorRect.top + activatorRect.height + content.scrollHeight + PADDING_FROM_ACTIVATOR > windowHeight
          ? windowHeight - content.scrollHeight
          : activatorRect.top + activatorRect.height + PADDING_FROM_ACTIVATOR;

        this.top = `${top}px`;

        const activatorLeft = activatorRect.left + activatorRect.width / 2 - content.scrollWidth / 2;

        const left = activatorLeft + contentRect.width > windowWidth
          ? windowWidth - contentRect.width
          : activatorLeft;

        this.left = `${Math.max(left, PADDING_FROM_SCREEN)}px`;
      },
    },

    watch: {
      modelValue(cur) {
        this.innerValue = cur;
      },

      value(cur) {
        if (cur) {
          this.showTooltip();
        } else {
          this.hideTooltip();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../common/sass/variables";

  .z-tooltip {
    position: fixed;
    z-index: 9999;
    max-width: min(calc(100vw - 32px), 286px);
    overflow-y: auto;
    overflow-x: hidden;
    background: $theme-text-400;
    color: $theme-grayscale-white_blackPlus;
    border-radius: $common-r1;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    padding: 5px 16px;
    text-transform: initial;
    width: auto;
    opacity: 1;
    pointer-events: none;
  }

  .z-tooltip__activator {
    display: inline-grid;
    grid-template-columns: minmax(0, 1fr);

    & > * {
      margin: 0;
      flex-grow: 1;
    }

    &.block {
      width: 100%;
    }
  }

  .v-enter-active,
  .v-leave-active {
    transition: transform $mainTransition;
  }

  .v-enter-from,
  .v-leave-to {
    transform: translate(0, -50%) scale(0);
  }
</style>
