<template>
  <div>
    <app-input
      v-model="valueInternal"
      v-bind="$attrs"
      :type="showPassword ? 'text' : 'password'"
      :label="$t('password.label')"
      :append-inner-icon="showPassword ? 'z-eye-off-outline' : 'z-eye-outline'"
      @click:append-inner-icon="toggleShowPassword"
    />
  </div>
</template>

<script>
  import AppInput from '../../../../../laravel/resources/assets/common/components.blocks/AppInput';

  export default {
    name: 'AppInputPassword',

    props: {
      value: [String, Number],
    },

    components: {
      AppInput,
    },

    emits: [
      'input',
    ],

    data: () => ({
      showPassword: false,
    }),

    computed: {
      valueInternal: {
        get() {
          return this.value;
        },

        set(value) {
          this.$emit('input', value);
        },
      },
    },

    methods: {
      toggleShowPassword() {
        this.showPassword = !this.showPassword;
      },
    },
  };
</script>
