<template>
  <page :has-back-link="false">
    <div class="body--title">
      {{ $t('school.registration.create') }}
    </div>

    <div class="body-content">
      <router-link
        to="/signup/email"
        class="email-button"
      >
        <span>
          {{ $t('school.registration.method_email') }}
        </span>
      </router-link>

      <o-auth-login
        :short="true"
        :region-id="regionId"
        @login="sendOAuthAnalytics($event)"
      />

      <agreement />
    </div>
  </page>
</template>

<script>
  import Page from './Common/Page';
  import Agreement from './Common/Agreement';
  import OAuthLogin from '../../../../laravel/resources/assets/school/components.blocks/OAuthLogin';
  import Analyticable from '../mixins/Analyticable';
  import UsesRegion from '@/mixins/UsesRegion';

  export default {
    name: 'SchoolRegistrationPage',
    components: {
      Page,
      Agreement,
      OAuthLogin,
    },

    mixins: [
      Analyticable,
      UsesRegion,
    ],

    methods: {
      sendOAuthAnalytics(provider) {
        this.sendMarketingData('Lead');
        this.sendMarketingData(`register_${provider}`);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "../../../../laravel/resources/assets/common/sass/variables";
  @import "../../../../laravel/resources/assets/common/sass/mixins/media-queries";
  @import "../../../../laravel/resources/assets/common/sass/mixins/typography";

  .body-content {
    margin: 0 auto;
    max-width: 400px;

    @include mediaXs {
      max-width: none;
    }
  }

  .email-button {
    background: $theme-elements-bg;
    border-radius: $common-r3;
    position: relative;
    padding: 20px 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    margin-bottom: 8px;

    &:after {
      width: 24px;
      height: 24px;
      content: '';
      background: url("https://widget.zenclass.ru/images/icons/email.png") center center no-repeat;
      background-size: contain;

      @include retina {
        background-image: url("https://widget.zenclass.ru/images/icons/email@2x.png");
      }
    }

    @include mediaXs {
      padding: 20px 16px;
    }
  }

  .agreement {
    margin-top: 96px;
  }
</style>
